<template>
  <div>
    <AppCard>
      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>
            {{ $t('SendMoneyReasonforTransfer.IncludePaymentReferenceText').value }}
          </template>
          <template #content>
            <AppInputToggle
              v-model="paymentReferenceStore.paymentReferenceForm.includeReference.$value"
              analytics-name="payment-reference-toggle"
            />
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>
    </AppCard>
    <div v-if="paymentReferenceStore.paymentReferenceForm.includeReference.$value">
      <AppInputDropdown
        v-model="paymentReferenceStore.selectedReferenceOption"
        :placeholder="$t('SendMoneyReasonForTransfer.PleaseSpecifyText').value"
        :label="$t('PaymentReferenceModal.PaymentReferenceText').value"
        :options="paymentReferenceStore.referenceOptions"
        selected-display-field="text"
        selected-value-field="value"
        :hidden="paymentReferenceStore.onlyAllowInvoiceOrderReferenceOption"
        class="reference-dropdown"
        @change="onRefenceOptionSelected"
      />

      <AppInputText
        v-if="paymentReferenceStore.selectedReferenceOption"
        v-model="paymentReferenceStore.paymentReferenceForm.referenceText.$value"
        :validation="paymentReferenceStore.paymentReferenceForm.referenceText"
        :label="paymentReferenceStore.paymentReferenceInputLabel"
        :disabled="paymentReferenceStore.getIsPaymentReferenceInputDisabled"
        :helper-text="getHelperTextIfApplicable()"
        @input="onPaymentReferenceInput"
      />
    </div>
  </div>
</template>

<script>
import { useValidation } from 'vue-composable'

import { ref, reactive, nextTick, watch, onBeforeMount, computed } from '@vue/composition-api'

import { required, helpers } from '@vuelidate/validators'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

import { useBusinessNameValidation } from '@galileo/composables/useRecipientHelpers'

import {
  useI18nStore,
  useAuthStore,
  useRecipientsStore,
  useSendMoneyStore,
  usePaymentReferenceStore,
  storeToRefs,
} from '@galileo/stores'

import {
  AppDescriptionList,
  AppInputText,
  AppDescriptionListItem,
  AppInputToggle,
  AppInputDropdown,
  AppCard,
} from '@oen.web.vue2/ui'

export default {
  name: 'PaymentReferenceModal',
  components: {
    AppDescriptionList,
    AppDescriptionListItem,
    AppInputText,
    AppInputToggle,
    AppInputDropdown,
    AppCard,
  },
  props: {
    recipientName: {
      type: String,
      required: true,
    },
  },
  emit: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()

    const { extractRegexGroup, maxNumOfCharacters } = useBusinessNameValidation('name')

    const isCorporate = useAuthStore().isCorporateAccount

    const paymentReferenceStore = usePaymentReferenceStore()

    const input = reactive({
      reference: '',
      isDisabled: false,
      label: '',
    })

    watch(
      () => props.recipientName,
      (value) => {
        if (paymentReferenceStore.selectedReferenceOption === 'RECIPIENT_NAME')
          paymentReferenceStore.paymentReferenceForm.referenceText.$value = value
      }
    )

    const onRefenceOptionSelected = (event) => {
      if (event.value === 'RECIPIENT_NAME')
        paymentReferenceStore.paymentReferenceForm.referenceText.$value = props.recipientName
    }

    let userProfile = authStore.userProfile

    const onPaymentReferenceInput = (value) => {
      if (value) emit('input', value)
    }

    const getHelperTextIfApplicable = () => {
      if (!input.isDisabled) {
        return $t('PaymentReferenceModal.InvoiceOrderNumberHelperText').value
      }
      return ''
    }

    return {
      $t,
      paymentReferenceStore,
      input,
      getHelperTextIfApplicable,
      props,
      isCorporate,
      onPaymentReferenceInput,
      onRefenceOptionSelected,
    }
  },
}
</script>

<style scoped>
::v-deep .toggle-button {
  &.on {
    @apply bg-orange;
    .knob {
      @apply translate-x-4;
      .halo {
        @apply bg-orange-lightest;
        .md-ripple-wave {
          @apply bg-orange;
        }
      }
    }
  }
}

::v-deep .description-list {
  padding-top: 14px;
  padding-bottom: 14px;
}

::v-deep .description-list-item-term {
  @apply font-normal !important;
}

::v-deep .card-content-block {
  margin: 0px !important;
}
::v-deep .card {
  @apply mb-6 !important;
  @apply shadow-ria-1;
  border-radius: 16px;
}

::v-deep .card-content {
  @apply px-6;
}

::v-deep .reference-dropdown.input--disabled .input-wrapper {
  color: black;
}
::v-deep .reference-dropdown.input--disabled .input-wrapper svg {
  display: none;
}
::v-deep .reference-dropdown.input--disabled .input-label {
  color: black;
}
</style>
