<template>
  <div>
    <!-- Modify recipient modal -->

    <AppModal
      v-model="showSelf"
      class="recipient-modal"
      :title="$t('ComponentRecipientDetailsModal.TitleModal').value"
    >
      <template #header>
        <AppModalHeader v-if="!recipient">
          <template #default>
            {{ firstStep ? $t('ComponentRecipientDetailsModal.TitleModal').value : stepHeader }}
          </template>

          <template #left>
            <XeBackButton
              theme="icon"
              icon="<"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="modify-recipient-modal-back-add"
              @click="backClick"
            />
          </template>

          <template #right>
            <XeBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              icon="x"
              analytics-name="bank-details-modal-back"
              @click="$emit('back', true)"
            />
          </template>
        </AppModalHeader>

        <AppModalHeader v-if="recipient">
          <template #default>{{ $t('ComponentModifyRecipientModal.EditHeader').value }}</template>

          <template #left>
            <XeBackButton
              icon="<"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="modify-recipient-modal-hide-edit"
              @click="backClick"
            />
          </template>

          <template #right>
            <XeBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              icon="x"
              @click="$emit('back', true)"
            />
          </template>
        </AppModalHeader>
      </template>

      <AppSpinnerBig :loading="loading || setupStepsLoading" class="relative" />

      <div v-if="!loading && !setupStepsLoading">
        <CardWarning v-if="missingInformation && isSendMoney">
          <template #text>
            {{ $t('ModifyRecipientModal.MissingInformationWarningText').value }}
            <!-- Please provide the missing information to continue with your transfer -->
          </template>
        </CardWarning>

        <CardWarning v-if="isConfirmName && !isSendMoney">
          <template #text>
            {{ $t('ModifyRecipientModal.ConfirmRecipientDetailsText').value }}
            <!-- Please provide the missing information to continue with your transfer -->
          </template>
        </CardWarning>

        <form @submit.prevent="submit">
          <AppAlert v-if="(firstStep && !myself) || isConfirmName" class="mb-6" theme="gray">
            {{ $t('ModifyRecipientModal.FraudNotice').value }}
            <AppAlertAction @click="onLearnMore">
              {{ $t('ModifyRecipientModal.FraudLearnMore').value }}
            </AppAlertAction>
          </AppAlert>

          <div
            v-if="
              (!isSendMoney && (firstStep || currentRecipientFormPage === 'nameFields')) ||
              (isO2OTransfer &&
                isSendMoney &&
                (firstStep || currentRecipientFormPage === 'nameFields'))
            "
          >
            <XeInputCurrency
              v-model="form.currency"
              data-testid="xe-add-recipient-currency-select"
              :label="$t('ModifyRecipientModal.LabelInputCurrency').value"
              :currencies="currenciesTo"
              :validation="validation.currency"
              :placeholder="$t('ModifyRecipientModal.InputCurrency').value"
              selected-display-field="description"
              @input="onCurrencyChange"
              :disabled="missingInformation || (isO2OTransfer && isSendMoney)"
            />

            <AppInputCountry
              v-model="form.country"
              :empty-filter-message="$t('AddressSearch.noResult').value"
              data-testid="xe-add-recipient-country-select"
              :disabled="
                !countries ||
                countriesList.loading.value ||
                countries.length === 0 ||
                missingInformation
              "
              :label="$t('ModifyRecipientModal.LabelInputCountry').value"
              :countries="countries"
              :validation="validation.country"
              :placeholder="$t('ModifyRecipientModal.InputCountry').value"
              :loading="countriesList.loading.value"
              @input="onCurrencyCountryChange"
            />
          </div>

          <AppInputCountry
            v-if="currentRecipientFormPage === 'addressFields'"
            v-model="form.addressCountry"
            data-testid="xe-address-search-country-select"
            :disabled="false"
            :label="$t('ModifyRecipientModal.LabelInputCountry').value"
            :countries="countries"
            @input="addressCountryChangeHandler"
          />

          <div
            v-if="
              currentRecipientFormPage !== 'addressFields' ||
              missingInformation ||
              fieldsVisible ||
              myself
            "
          >
            <AppInputCountry
              :empty-filter-message="$t('AddressSearch.noResult').value"
              v-if="currentRecipientFormPage === 'bankFields'"
              v-model="form.country"
              data-testid="add-recipient-bank-country-select"
              :disabled="true"
              :label="$t('ModifyRecipientModal.LabelInputCountry').value"
              :countries="countries"
            />
          </div>

          <div
            v-if="
              shouldShowAddressFields() &&
              currentRecipientFormPage === 'addressFields' &&
              (!fieldsVisible || (myself && addressCountryChanged))
            "
          >
            <XeAddressSearch
              :dropdown-label="$t('ModifyRecipientModal.SearchAddress').value"
              data-testid="xe-address-search"
              :search-promise-lazy="searchPromiseLazy"
              :get-address-details-promise-lazy="getAddressDetailsPromiseLazy"
              :show-manual-selection="!missingInformation && !fieldsVisible"
              @select="onSelectAddress"
              @manual="onManualAddress"
            />
          </div>

          <AppInputText
            v-if="showFullNameField && currentRecipientFormPage === 'nameFields'"
            v-model="form.name"
            data-testid="xe-add-recipient-full-name"
            :label="$t('ModifyRecipientModal.LabelInputName').value"
            :placeholder="$t('ModifyRecipientModal.InputName').value"
            :validation="validation.name"
            :helper-text="$t('ComponentEditRecipientDetailsModal.HelperText').value"
            class="full-name"
          >
            <template #labelRight>
              <XeTooltip
                activation="click"
                side="bottom"
                data-testid="xe-full-name-tooltip"
                class="full-name-tooltip"
              >
                <template #wrappedElement>
                  <AppIcon name=" ">
                    <IconInformation />
                  </AppIcon>
                </template>

                <template #tooltipContent>
                  <div class="mb-6">
                    <span class="rule-list-item-title">
                      {{
                        $t(
                          'ComponentModifyRecipientModal.DialogFullNameInfo.TitleGettingTheNameRight'
                        ).value
                      }}
                    </span>

                    <p class="rule-list-item-text">
                      {{
                        $t(
                          'ComponentModifyRecipientModal.DialogFullNameInfo.TextGettingTheNameRight'
                        ).value
                      }}
                    </p>
                  </div>

                  <div class="mb-6">
                    <span class="rule-list-item-title">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TitleNoTitleNeeded')
                          .value
                      }}
                    </span>

                    <p class="rule-list-item-text">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TextNoTitleNeeded')
                          .value
                      }}
                    </p>
                  </div>

                  <div>
                    <span class="rule-list-item-title">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TitleJointAccount')
                          .value
                      }}
                    </span>

                    <p class="rule-list-item-text">
                      {{
                        $t('ComponentModifyRecipientModal.DialogFullNameInfo.TextJointAccount')
                          .value
                      }}
                    </p>
                  </div>
                </template>
              </XeTooltip>
            </template>
          </AppInputText>
          <AppDynamicForm v-show="fieldsVisible" :key="formFields.length" :fields="formFields" />
          <div v-if="isSendMoney">
            <SendMoneyReasonForTransfer
              v-if="form.country && firstStep"
              :country="form.country"
              :recipient-name="recipientName"
            />
          </div>
          <div
            v-if="
              currentRecipientFormPage !== 'addressFields' ||
              missingInformation ||
              fieldsVisible ||
              myself
            "
          >
            <div v-if="industryCategoryType.show && currentRecipientFormPage === 'bankFields'">
              <AppInputDropdown
                v-model="industryCategoryType.selectedCategory"
                :label="$t('ModifyRecipientModal.LabelIndustryCategory').value"
                :placeholder="$t('ModifyRecipientModal.InputIndustryCategory').value"
                :options="industryCategoryType.categories"
                analytics-name="industry-category-dropdown"
                selected-display-field="description"
                selected-value-field="categoryId"
              />

              <AppInputDropdown
                v-if="
                  industryCategoryType.selectedCategory &&
                  industryCategoryType.selectedCategory !== ''
                "
                v-model="industryCategoryType.selectedType"
                :label="$t('ModifyRecipientModal.LabelIndustryType').value"
                :placeholder="$t('ModifyRecipientModal.InputIndustryType').value"
                :options="industryCategoryType.filteredTypes"
                analytics-name="industry-type-dropdown"
                selected-display-field="name"
                selected-value-field="industryTypeId"
              />
            </div>

            <div v-if="currentRecipientFormPage === 'bankFields' && additionalFields.length > 0">
              <div>{{ $t('ModifyRecipientModal.AdditionalDetails').value }}</div>
              <AppDynamicForm :fields="additionalFields" />
            </div>
          </div>
        </form>
      </div>

      <template #footer class="card-footer">
        <AppModalFooter>
          <AppButton
            v-if="firstStep || fieldsVisible || myself || missingInformation"
            analytics-name="modify-recipient-modal-submit"
            :loading="loading || submitting"
            :disabled="
              isFormInvalid ||
              formValidationSwitcher ||
              (paymentReferenceStore.paymentReferenceForm.$anyInvalid && isSendMoney) ||
              (industryCategoryType.show &&
                !industryCategoryType.selectedType &&
                currentRecipientFormPage === 'bankFields') ||
              (currentRecipientFormPage === 'bankFields' &&
                additionalFields.length > 0 &&
                additionalValidation.$anyInvalid)
            "
            type="submit"
            @click="submit"
          >
            {{ $t('ComponentModifyRecipientModal.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <AppDialog v-model="showFraudInfo" class="fraud-info-modal">
      <AppDialogHeader class="consent-header">
        <!-- <XeBackButton @click="onFraudGotIt" class="consent-header-back-button" /> -->
      </AppDialogHeader>

      <AppCardImageTitle
        :title="$t('ComponentModifyRecipientModal.TitleFraudDialog').value"
        :src="require('@galileo/assets/images/illustrations/em_xe.svg')"
        alt="Take a moment and stop fraud"
        class="cardImage"
      >
        <p class="infoDialog">{{ $t('ComponentModifyRecipientModal.FraudDialogInfo').value }}</p>
      </AppCardImageTitle>

      <AppDialogFooter>
        <AppButton analytics-name="modify-recipient-fraud-dialog-ok" @click="onFraudGotIt">
          {{ $t('ComponentModifyRecipientModal.ButtonFraudGotIt').value }}
        </AppButton>

        <AppButton
          analytics-name="modify-recipient-fraud-dialog-contact-us"
          theme="text"
          :loading="loadingContactUs"
          @click="onFraudContactUs"
        >
          {{ $t('ComponentModifyRecipientModal.ButtonVisitHelpCenter').value }}
        </AppButton>
      </AppDialogFooter>
    </AppDialog>
  </div>
</template>

<script>
import { computed, ref, toRef, reactive, onBeforeMount } from '@vue/composition-api'
import { useValidation, usePromiseLazy } from 'vue-composable'

import getValueRequiredValidation from '@galileo/utilities/validations.utility'

import { getAllISOByCurrency } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import { useRecipientForm } from '@galileo/forms/RecipientForm'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch'
import XeInputCurrency from '@galileo/components/XeInputCurrency/XeInputCurrency'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import SendMoneyReasonForTransfer from '@galileo/components/Views/SendMoney/SendMoneyReasonForTransfer/SendMoneyReasonForTransfer'
import { useIndustryType } from '@galileo/composables/useEditBankDetails'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import CardWarning from '@galileo/components/CardWarning/CardWarning'
import { useFullNameValidation } from '@galileo/composables/useRecipientHelpers'
import useRecipientAddressFiller from '@galileo/composables/useAddress/useRecipientAddressFiller'

import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

import { parseSepaFields } from '@galileo/composables/useRecipientHelpers/useRecipientUpdate'

import {
  IconTrashCanFilled,
  IconCloseFilled,
  IconChevronRightFilled,
  IconInformation,
} from '@oen.web.vue2/icons'

import {
  useVModel,
  AppDialogHeader,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppDialog,
  AppDialogFooter,
  AppCardImageTitle,
  AppButton,
  AppIcon,
  AppDynamicForm,
  AppBackButton,
  AppInputText,
  AppInputCountry,
  AppAlert,
  AppAlertAction,
  AppInputDropdown,
  AppDescriptionList,
  AppDescriptionListItem,
  AppInputToggle,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useResourcesStore,
  useAppStore,
  useAnalyticsStore,
  useRecipientsStore,
  useSendMoneyStore,
  useAuthStore,
  usePaymentReferenceStore,
} from '@galileo/stores'

import { storeToRefs } from 'pinia'
import { first } from 'lodash-es'

export default {
  name: 'ModifyRecipientModal',
  emits: ['input', 'submit', 'delete', 'back'],
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppDialogHeader,
    AppDialog,
    AppDialogFooter,
    AppCardImageTitle,
    AppButton,
    AppIcon,
    IconTrashCanFilled,
    IconCloseFilled,
    IconChevronRightFilled,
    IconInformation,
    AppDynamicForm,
    AppBackButton,
    AppInputText,
    AppInputCountry,
    AppAlert,
    AppAlertAction,
    AppInputDropdown,
    AppDescriptionList,
    AppDescriptionListItem,
    AppInputToggle,
    XeAddressSearch,
    XeInputCurrency,
    XeBackButton,
    XeTooltip,
    SendMoneyReasonForTransfer,
    CardWarning,
    AppSpinnerBig,
  },
  props: {
    missingInformation: {
      type: Boolean,
      required: false,
      default: false,
    },
    isConfirmName: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    recipient: {
      type: Object,
      required: false,
      default: null,
    },
    myself: {
      type: Boolean,
      required: false,
      default: false,
    },
    business: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const resourcesStore = useResourcesStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const valueRequired = getValueRequiredValidation()
    const {
      setup,
      nextFormFields,
      previousFormFields,
      selectFormFields,
      currentRecipientFormPage,
      formFields,
      formValidation,
      hasFormFields,
      isFormInvalid,
      allGroups,
      allFields,
      additionalFields,
      additionalValidation,
      setFormFieldValue,
      shouldShowAddressFields,
    } = useRecipientForm(props.missingInformation)

    //checking if it's a O2O contract
    const isO2OTransfer = computed(() => sendMoneyStore.isO2OContract)

    const { setupIndustryType, selectedIndustryType } = useIndustryType()
    const loading = ref(true)
    const firstStep = ref(true)
    const loadedFields = ref([])
    const fieldsVisible = ref(true)
    const { model } = useVModel(props, emit)
    const showSelf = ref(model.value)

    const defaultForm = {
      name: '',
      nickname: '',
      country: '',
      addressCountry: '',
      currency: '',
      loading: false,
    }

    let form = reactive({
      ...defaultForm,
    })

    const showFullNameField = ref(true)
    const { isCorporateAccount } = storeToRefs(authStore)
    const phoneCountries = resourcesStore.getPhoneCountries

    const { onSelectAddress, onCountryChange } = useRecipientAddressFiller(
      setFormFieldValue,
      () => (fieldsVisible.value = true)
    )

    const showFraudInfo = ref(false)

    let defaultValues = {}

    const userProfile = computed(() => {
      return authStore.userProfile
    })

    const countriesList = usePromiseLazy((currency) =>
      recipientsStore.getCountriesForCurrency(currency)
    )

    const countries = ref([])
    const loadingContactUs = ref(false)
    const shouldStayOnSamePage = ref(false)

    const onCurrencyChange = async () => {
      if (!form.currency || props.isSendMoney) return

      countries.value = await countriesList.exec(form.currency)
      const matching = getAllISOByCurrency(form.currency)
      if (!countries.value) {
        form.country = ''
      } else if (matching && matching.length > 0 && matching.length < 5) {
        form.country = matching[0]
      }

      if (formFields.value.length) {
        await onCurrencyCountryChange()
      }
    }

    const onCurrencyCountryChange = async () => {
      if (currentRecipientFormPage.value === 'nameFields' || isO2OTransfer.value) {
        selectedAddressCountry = null
        if (!props.myself) {
          form.addressCountry = form.country
        }
        loading.value = true
        shouldStayOnSamePage.value = true
        await fetchFormFields()
        if (isO2OTransfer.value) {
          firstStep.value = true
        }
      } else if (currentRecipientFormPage.value !== null) {
        currentRecipientFormPage.value = null
        firstStep.value = true
      }
    }

    const currenciesTo = computed(() => {
      return resourcesStore.getCombinedCurrenciesTo
    })

    const onLearnMore = () => {
      showFraudInfo.value = true
    }
    const onFraudGotIt = () => {
      showFraudInfo.value = false
    }
    const onFraudContactUs = () => {
      appStore.openHelpDesk({ loadingRef: loadingContactUs })
    }

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: form.addressCountry || form.country,
        })
        return result
      })
    )
    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => resourcesStore.getAddressDetails(selectedId))
    )

    const {
      containsInvalidWordsOrPrefixes,
      containsInvalidCharacters,
      containsFirstAndLastName,
      containsAtLeastTwoCharsInNames,
    } = useFullNameValidation('name', props.business)

    const formValidationSwitcher = computed(() => {
      if (showFullNameField.value && currentRecipientFormPage.value !== null) {
        return validation.$anyInvalid
      }
      return validation.country.$anyInvalid || validation.currency.$anyInvalid
    })

    const validation = useValidation({
      name: {
        $value: toRef(form, 'name'),
        valueRequired,
        containsInvalidWordsOrPrefixes,
        containsInvalidCharacters,
        containsFirstAndLastName,
        containsAtLeastTwoCharsInNames,
      },
      country: {
        $value: toRef(form, 'country'),
        valueRequired,
      },
      currency: {
        $value: toRef(form, 'currency'),
        valueRequired,
      },
    })

    onBeforeMount(async () => {
      await onShow()
    })

    const onShow = async () => {
      for (const name in defaultForm) {
        form[name] = defaultForm[name]
      }

      if (props.isSendMoney) {
        paymentReferenceStore.resetPaymentReferenceForm()
      }

      validation.$reset()

      loading.value = true

      if (props.isSendMoney && !props.recipient) {
        form.currency = sendMoneyStore.form.currencyTo
        countries.value = await countriesList.exec(form.currency)
        form.country = sendMoneyStore.getCountryTo
      }

      if (props.recipient) {
        const recipient = props.recipient
        form.name = `${recipient.firstName} ${recipient.lastName}`
        form.nickname = recipient.nickName
        form.country = recipient.country
        form.currency = recipient.currency
        form.addressCountry = recipient.addressCountry
        await onCurrencyChange()
      } else if (!props.myself) {
        form.addressCountry = form.country
      }

      if (props.myself) {
        form.name = userProfile.value.customer.firstName + ' ' + userProfile.value.customer.lastName
        if (!props.recipient) {
          form.addressCountry = userProfile.value.country
        }
        if (isCorporateAccount.value) {
          form.name = userProfile.value.customer.accountName
        }
        await onCurrencyChange()
      }
      try {
        if (props.isSendMoney) {
          loading.value = true
          shouldStayOnSamePage.value = true
          if (!isO2OTransfer.value) {
            await fetchFormFields()
            firstStep.value = true
            setupStep()
          }
        }
      } catch (ex) {
        useAppStore().logException('Failed fetching recipient fields')
      }

      if (props.missingInformation) {
        firstStep.value = false
        fieldsVisible.value = true
        await fetchFormFields()
        formValidation.value.$touch()
      }
      loading.value = false
    }

    const populateDefaultAddressFields = (defaultValues) => {
      if (!addressCountryChanged.value) {
        defaultValues['ADDRESS_LINE1'] = props.recipient.formattedAddress.street
        defaultValues['ADDRESS_LINE2'] = props.recipient.formattedAddress.city
        defaultValues['RECIPIENT_POST_CODE'] =
          props.recipient.recipientPostCode ?? props.recipient.postalCode
        defaultValues['EMAIL'] = props.recipient.emailAddress
        defaultValues['RECIPIENT_CITY'] = props.recipient.recipientCity
        defaultValues['RECIPIENT_STATE'] = props.recipient.recipientState
        defaultValues['RECIPIENT_PHONE'] = props.recipient.telephoneNumber
      } else {
        defaultValues['ADDRESS_LINE1'] = ''
        defaultValues['ADDRESS_LINE2'] = ''
        defaultValues['RECIPIENT_POST_CODE'] = ''
        defaultValues['EMAIL'] = ''
        defaultValues['RECIPIENT_CITY'] = ''
        defaultValues['RECIPIENT_STATE'] = ''
        defaultValues['RECIPIENT_PHONE'] = ''
      }
    }

    const populateDefaultValues = () => {
      //populate the selectedAddressCountry if it's not set

      if (!selectedAddressCountry) {
        selectedAddressCountry = form.addressCountry
      }

      const recipientDefaultPhonePrefix = phoneCountries.find((country) => {
        return country.nameAbbr === form.country
      })
      let shouldClearValues = true
      if (addressCountryChanged.value || shouldStayOnSamePage.value) {
        shouldClearValues = false
      }

      let defaultValues = {}
      if (props.recipient) {
        defaultValues = props.recipient
        if (props.recipient.deliveryMethod === 'BankAccount') {
          //can be replaced with : import { useDefaultValues } from '@galileo/composables/useFormHelpers' ?
          defaultValues['CURRENCY_CODE'] = form.currency
          defaultValues['COUNTRY_CODE'] = form.country
          defaultValues['ACCOUNT_NAME'] = form.name
          defaultValues['FIRST_NAME'] = props.recipient.firstName
          defaultValues['MIDDLE_NAME'] = props.recipient.middleName
          defaultValues['LAST_NAME'] = props.recipient.lastName
          defaultValues['NICKNAME'] = form.nickname
          defaultValues['BANK_NAME'] = props.recipient.bankName
          defaultValues['ACCOUNT_NUM'] = props.recipient.bankAccountNumber
          defaultValues['BANK_BIC'] = props.recipient.bankBicSwift
          defaultValues['BANK_ACCOUNT_TYPE_CODE'] = props.recipient.bankAccountTypeId
          defaultValues['CA_TRANSIT_INSTITUTION'] = props.recipient.institutionNumber
          defaultValues['CA_TRANSIT_BRANCH'] = props.recipient.branchNumber
          defaultValues['NAT_CLEAR_CODE'] = props.recipient.bankRoutingCode
          defaultValues['IBAN'] = props.recipient.bankIban
          defaultValues['IBAN_OR_ACCOUNT_NUM'] = props.recipient.ibanOrAccountNumber
          defaultValues['ADDRESS_COUNTRY_CODE'] = props.recipient.addressCountry
          if (addressCountryChanged.value) {
            defaultValues['ADDRESS_COUNTRY_CODE'] = form.addressCountry
          }
          defaultValues['ID_NUMBER_TYPE_CODE'] = props.recipient.recipientIdNumberCode
          defaultValues['RECIPIENT_ID_NUMBER'] = props.recipient.recipientIdNumber
          defaultValues['RECIPIENT_TAX_NUMBER'] = props.recipient.taxNumber
          defaultValues['INDUSTRY_TYPE'] = props.recipient.industryTypeId
          populateDefaultAddressFields(defaultValues)
        }
        shouldClearValues = false
      } else {
        defaultValues['ACCOUNT_NAME'] = form.name
        defaultValues['NICKNAME'] = form.nickname
        defaultValues['CURRENCY_CODE'] = form.currency
        defaultValues['COUNTRY_CODE'] = form.country
        defaultValues['ADDRESS_COUNTRY_CODE'] = form.country
        defaultValues['RECIPIENT_PHONE'] = recipientDefaultPhonePrefix?.value.replace('+', '')
        if (props.myself && userProfile.value && !addressCountryChanged.value) {
          form.addressCountry = userProfile.value.country
          defaultValues['FIRST_NAME'] =
            userProfile.value.customer?.firstName || form.name.split(' ')[0]
          defaultValues['MIDDLE_NAME'] = userProfile.value.customer?.middleName
          defaultValues['LAST_NAME'] =
            userProfile.value.customer?.lastName || form.name.split(' ').slice(1).join(' ')
          defaultValues['ADDRESS_COUNTRY_CODE'] = form.addressCountry
          defaultValues['RECIPIENT_CITY'] = removeDiacritics(translit(userProfile.value.city))
          defaultValues['RECIPIENT_POST_CODE'] = userProfile.value.postalCode
          const { getUserAddressAsRecipient } = storeToRefs(authStore)
          defaultValues['ADDRESS_LINE1'] = removeDiacritics(
            translit(getUserAddressAsRecipient.value)
          )
          defaultValues['ADDRESS_LINE2'] = removeDiacritics(translit(userProfile.value.city))
        }
      }

      defaultValues['IS_BUSINESS'] = props.business.toString()
      defaultValues['IS_OWN_ACCOUNT'] = props.myself.toString()

      return { defaultValues, shouldClearValues }
    }

    let addressCountryChanged = ref(false)
    let selectedAddressCountry = null

    const addressCountryChangeHandler = async (country) => {
      //fetch fields only in case the selecterd address country is different
      //in order not to clear the inserted values
      if (country !== selectedAddressCountry) {
        selectedAddressCountry = country
        loading.value = true
        addressCountryChanged.value = true
        shouldStayOnSamePage.value = false
        await onCountryChange()
        await fetchFormFields()
      }
    }

    const recipientName = computed(() => {
      if (!showFullNameField.value) {
        if (formFields?.value.length > 0) {
          const firstName = formFields.value.find((field) => field.id === 'FIRST_NAME')?.value
          const lastName = formFields.value.find((field) => field.id === 'LAST_NAME')?.value
          if (firstName && lastName) {
            form.name = `${firstName} ${lastName}`
          } else {
            const accountNameField = formFields.value.find((field) => field.id == 'ACCOUNT_NAME')
            if (accountNameField) {
              form.name = accountNameField.value
            }
          }
        }
      }
      return form.name
    })

    const fetchFormFields = async () => {
      loading.value = true

      if (!selectedAddressCountry) {
        selectedAddressCountry = form.addressCountry
      }

      try {
        let fetchedFields = await recipientsStore.getFields({
          id: userProfile.value.customer.id,
          country: form.country,
          currency: form.currency,
          isBusiness: props.business,
          addressCountry: selectedAddressCountry,
        })

        if (fetchedFields) {
          const hasFirstNameField = fetchedFields.find((field) => field.id === 'FIRST_NAME')

          if (hasFirstNameField || props.business) {
            showFullNameField.value = false
          }

          loadedFields.value = fetchedFields

          let result = populateDefaultValues()
          defaultValues = result.defaultValues
          let shouldClearValues = result.shouldClearValues

          if (props.isSendMoney) {
            shouldClearValues = false
          }

          setup(
            loadedFields.value,
            defaultValues,
            shouldClearValues,
            props.business,
            shouldStayOnSamePage.value
          )

          shouldStayOnSamePage.value = false

          if (formFields.value) {
            firstStep.value = false
            loading.value = false
            return
          }
        }
      } catch (ex) {
        appStore.logException('Exception during fetching recipient fields', ex)
      }
      appStore.messageBoxGenericError()
      loading.value = false
    }

    const errorCallback = (ex) => {
      if (ex?.response?.data?.data?.errors) {
        for (const element of ex.response.data.data.errors) {
          const error = element
          const errorId = error.id.toUpperCase()

          for (const name in allGroups.value) {
            const group = allGroups.value[name]
            for (const element of group) {
              const field = element
              if (
                field.id.replace('_', '') === errorId ||
                (errorId === 'ACCOUNTNUMBER' && (field.id === 'IBAN' || field.id === 'ACCOUNT_NUM'))
              ) {
                field.errorValue = field.value
                selectFormFields(name)
                setupStep()
                return true
              }
            }
          }

          for (const element of additionalFields.value) {
            const field = element
            if (field.id.replace('_', '') === errorId) {
              selectFormFields('bankFields')
              setupStep()
              return true
            }
          }
        }
      }
      return false
    }

    const submitRecipient = async () => {
      // for business recipient we allow "&" char in the front-end as per useFieldRules
      // but as the backend doesn't accept that char we change it to "and" seemless to the user
      if (props.business) {
        const accountNameField = allFields.value.find((field) => field.id == 'ACCOUNT_NAME')
        if (accountNameField) {
          accountNameField.value = accountNameField.value.replace('&', 'and')
        }
      } else if (showFullNameField.value) {
        const accountNameField = allFields.value.find((field) => field.id == 'ACCOUNT_NAME')
        accountNameField.value = form.name
      }

      setFormFieldValue('ADDRESS_COUNTRY_CODE', form.addressCountry)

      if (selectedIndustryType.value) {
        //setting industry type
        setFormFieldValue('INDUSTRY_TYPE', selectedIndustryType.value)
      }

      const nameVerifiedRequired =
        props.recipient?.nameVerified === false && props.missingInformation

      // If there's a name field being sent, make it SEPA compliant
      const nameFields = [
        'ACCOUNT_NAME',
        'FIRST_NAME',
        'MIDDLE_NAME',
        'LAST_NAME',
        'NICKNAME',
        'FirstName',
        'MiddleName',
        'LastNames',
      ]
      parseSepaFields(allFields.value, nameFields)

      let payload = {
        currency: form.currency,
        country: form.country,
        isOwnAccount: props.myself,
        isBusiness: props.business,
        ...(nameVerifiedRequired && {
          nameVerified: true,
        }),
        fields: allFields.value,
        errorCallback,
      }

      if (props.recipient) {
        await editRecipientSubmit(payload)
      } else {
        await addRecipientSubmit(payload)
      }
    }

    const industryCategoryType = ref({})

    const stepHeader = ref('')

    const setupStepsLoading = ref(false)
    const setupStep = async () => {
      setupStepsLoading.value = true
      fieldsVisible.value = false

      // Fire address started event
      if (currentRecipientFormPage.value === 'addressFields') {
        if (!form.addressCountry) {
          form.addressCountry = form.country
        }

        //get all countries to display in address fields
        countries.value = resourcesStore.getCountries
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_ADDRESS_STARTED,
          traits: {
            recipientCountry: form.country,
          },
        })
      }

      // set visible to false and then to true so that the dynamic form gets recreated and not recycled otherwise it causes problems like validation not working fully
      window.requestAnimationFrame(async () => {
        if (currentRecipientFormPage.value === 'addressFields') {
          if (props.missingInformation) {
            fieldsVisible.value = true
          } else if (!props.myself) {
            fieldsVisible.value = false
          }
          stepHeader.value = $t('ComponentRecipientDetailsModal.TitleRecipientAddressDetails').value
        } else if (currentRecipientFormPage.value === 'bankFields') {
          fieldsVisible.value = true
          stepHeader.value = $t('ComponentRecipientDetailsModal.TitleRecipientBankDetails').value
        } else if (currentRecipientFormPage.value === 'nameFields') {
          fieldsVisible.value = true
          stepHeader.value = $t('ComponentRecipientDetailsModal.TitleRecipientDetails').value
        }
        // else {
        //   fieldsVisible.value = true
        //   stepHeader.value = $t('ComponentRecipientDetailsModal.TitleRecipientDetails').value
        // }

        let industryType = selectedIndustryType.value
        if (props.recipient) {
          industryType = props.recipient.industryTypeId || industryType
        }

        industryCategoryType.value = await setupIndustryType(
          allFields.value,
          props.business,
          industryType
        )

        if (props.myself) {
          form.addressCountry = form.addressCountry || form.country
        } else if (!selectedAddressCountry) {
          form.addressCountry = form.country || form.addressCountry
        }
        const delay = setTimeout(() => (setupStepsLoading.value = false), 400)
      })
    }

    const backClick = () => {
      if (firstStep.value) {
        emit('back')
      } else if (currentRecipientFormPage.value == 'addressFields' && fieldsVisible.value) {
        fieldsVisible.value = false
      } else if (currentRecipientFormPage.value == 'bankFields' && fieldsVisible.value) {
        previousFormFields()
        if (props.isSendMoney) {
          fieldsVisible.value = true
          firstStep.value = true
        }
        setupStep()
      } else if (previousFormFields()) {
        setupStep()
      } else {
        currentRecipientFormPage.value = null

        fieldsVisible.value = false
        firstStep.value = true
      }
      addressCountryChanged.value = false
    }

    const onManualAddress = () => {
      //we reset the fields when going all the fields
      onCountryChange()
      fieldsVisible.value = true
    }

    const submit = async () => {
      loading.value = true

      // Fire bank added or address completed events
      if (currentRecipientFormPage.value == 'bankFields') {
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_BANK_DETAILS_ADDED,
          traits: {},
        })
      } else if (currentRecipientFormPage.value == 'addressFields') {
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_LOCATION_ADDED,
          traits: {
            currency: form.currency,
            country: form.country,
          },
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_ADDRESS_COMPLETED,
          traits: {
            recipientCountry: form.country,
          },
        })
      } else if (!firstStep.value || (props.isSendMoney && firstStep.value)) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_INFO_ADDED,
          traits: {
            location: props.isSendMoney
              ? SEGMENT_LOCATIONS.SEND_MONEY
              : SEGMENT_LOCATIONS.RECIPIENT_LIST,
            recipientCountry: form.country,
          },
        })
      }

      if (firstStep.value && props.isSendMoney) {
        //store the data to reason for trasnfer
        paymentReferenceStore.updateSMFormWithPaymentReference()
      }

      if (
        firstStep.value &&
        ((defaultValues['ADDRESS_COUNTRY_CODE'] !== form.country &&
          defaultValues['CURRENCY_CODE'] !== form.currency) ||
          !currentRecipientFormPage.value)
      ) {
        await fetchFormFields()
        setupStep()
      } else if (firstStep.value && currentRecipientFormPage.value) {
        nextFormFields()
        setupStep()
        firstStep.value = false
        fieldsVisible.value = true
      } else if (!props.isSendMoney && nextFormFields(firstStep.value)) {
        setupStep()
        firstStep.value = false
        fieldsVisible.value = true
      } else if (nextFormFields()) {
        nextFormFields(firstStep.value)
        setupStep()
        firstStep.value = false
        fieldsVisible.value = true
      } else {
        if (selectedIndustryType.value) {
          //setting industry type
          setFormFieldValue('INDUSTRY_TYPE', selectedIndustryType.value)
        }
        await submitRecipient()
      }

      if (props.missingInformation) {
        formValidation.value.$touch()
      }
      addressCountryChanged.value = false
      const delay = setTimeout(() => (loading.value = false), 500)
    }

    const addRecipientSubmit = async (addRecipientFormData) => {
      loading.value = true

      let accNameField = addRecipientFormData.fields.find((field) => field.id === 'ACCOUNT_NAME')

      if (accNameField) {
        accNameField.value ||= form.name
      }
      // setFormFieldValue('ACCOUNT_NAME', form.name)
      // setFormFieldValue('NICKNAME', form.nickname)

      try {
        const newRecipientResponse = await recipientsStore.addRecipient({
          country: addRecipientFormData.country,
          currency: addRecipientFormData.currency,
          fields: addRecipientFormData.fields,
          myself: props.myself,
          business: props.business,
          isSendMoney: props.isSendMoney,
        })
        await recipientsStore.getRecipients()
        emit('success', newRecipientResponse)
      } catch (ex) {
        //ex.response.data.data.errors[0]
        if (addRecipientFormData.errorCallback && addRecipientFormData.errorCallback(ex)) {
          loading.value = false
          return
        }
        appStore.logException('Exception during adding recipient in recipients', ex)
        appStore.messageBoxGenericError()
      } finally {
        loading.value = false
      }
    }

    const editRecipientSubmit = async (addRecipientFromData) => {
      loading.value = true
      if (selectedIndustryType.value) {
        //setting industry type
        setFormFieldValue('INDUSTRY_TYPE', selectedIndustryType.value)
      }

      const fields = []
      addRecipientFromData.fields.forEach((field) => {
        if (
          field.value ||
          field.id === 'MIDDLE_NAME' ||
          field.id === 'NICKNAME'
          //|| field.fieldDefinition.disableErrorValueValidation
        ) {
          fields.push({
            name: field.id,
            value: field.value,
          })
        }
      })

      addRecipientFromData.fields = fields

      try {
        const updatedRecipient = await recipientsStore.updateRecipient({
          id: props.recipient.id,
          formData: addRecipientFromData,
        })
        emit('success', updatedRecipient)
      } catch (ex) {
        appStore.logException('Exception during updating recipient in recipients', ex)
        appStore.messageBoxGenericError()
      } finally {
        loading.value = false
      }
    }

    return {
      model,
      submit,
      formFields,
      hasFormFields,
      formValidation,
      isFormInvalid,
      firstStep,
      loading,
      countries,
      currenciesTo,
      form,
      validation,
      onShow,
      backClick,
      countriesList,
      onCurrencyChange,
      onCurrencyCountryChange,
      onLearnMore,
      currentRecipientFormPage,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      fieldsVisible,
      onSelectAddress,
      onManualAddress,
      additionalFields,
      additionalValidation,
      stepHeader,
      showFraudInfo,
      onFraudGotIt,
      onFraudContactUs,
      $t,
      industryCategoryType,
      loadingContactUs,
      showSelf,
      shouldShowAddressFields,
      isO2OTransfer,
      onCountryChange,
      showFullNameField,
      formValidationSwitcher,
      addressCountryChangeHandler,
      addressCountryChanged,
      recipientName,
      paymentReferenceStore,
      setupStepsLoading,
      allFields,
    }
  },
}
</script>

<style scoped>
.recipient-modal {
  ::v-deep .card-content {
    .card-content-block {
      @apply mb-8;
    }
    .p-dropdown-items-wrapper {
      width: 100%;
    }
    .input-label {
      @apply text-base;
    }
  }
  ::v-deep .input-phone-dropdown {
    .p-dropdown-items-wrapper {
      width: auto !important;
    }
  }

  .full-name-tooltip {
    height: 24px;
    bottom: 1px;
    left: 3px;

    svg {
      color: #636e82 !important;
    }
  }
}

::v-deep .fraud-info-modal {
  .card-content {
    @apply p-0;
  }
}

.icon-trash-can.button.button--icon {
  @screen sm {
    @apply w-6 h-6 !important;
    &:hover:not(:disabled) {
      @apply bg-transparent;
    }
    ::v-deep .button-inner {
      @apply w-6 h-6;
      @apply text-gray-dark;
      &:hover {
        @apply text-gray-darker;
      }
    }
  }
}
.card-footer {
  @apply pt-0;
  @apply shadow-none;
  .button {
    @apply min-w-full;
  }
}

.rule-list-item-title {
  @apply font-medium;
}

.rule-list-item-text {
  @apply font-normal mt-2;
}
.cardImage {
  margin-top: 1.5rem;
}
.consent-header {
  @apply flex p-0 mt-0;
  float: right;
}
.consent-header-back-button {
  @apply mt-0 w-6;
  max-width: 1.5rem;
}

.infoDialog {
  @apply text-base;
  @apply font-normal;
}

.full-name {
  ::v-deep .tooltip-wrapper {
    @apply relative text-gray-dark mx-1;
    bottom: -6px;

    > div:not(.tooltip-tip) {
      @apply flex justify-center;
    }
  }
}

::v-deep .alert--gray {
  @apply bg-gray-lighter border-gray-lighter;

  .alert-action {
    @apply text-secondary-text;
    &:hover,
    &:focus {
      @apply bg-gray-light;
    }
  }
}
</style>
