<template>
  <AppList>
    <XeListItem
      analytics-name="add-recipient-type-myself"
      :text="$t('ComponentAddRecipientTypeModal.ButtonMyself').value"
      :subTitle="$t('ComponentAddRecipientTypeModal.ButtonMyselfDescription').value"
      :select="() => $emit('addMyself')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconMyself />
        </AppIcon>
      </template>
    </XeListItem>
    <XeListItem
      analytics-name="add-recipient-type-someone-else"
      :text="$t('ComponentAddRecipientTypeModal.ButtonSomeoneElse').value"
      :select="() => $emit('addSomeoneElse')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconSomeoneElse />
        </AppIcon>
      </template>
    </XeListItem>

    <XeListItem
      analytics-name="add-recipient-type-business"
      :text="$t('ComponentAddRecipientTypeModal.ButtonBusiness').value"
      :subTitle="$t('ComponentAddRecipientTypeModal.ButtonBusinessDescription').value"
      :select="() => $emit('addBusiness')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconBusiness />
        </AppIcon>
      </template>
    </XeListItem>
  </AppList>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import XeListItem from '@galileo/components/XeListItem/XeListItem'

import { AppList, AppIcon } from '@oen.web.vue2/ui'

import { IconMyself, IconSomeoneElse, IconBusiness } from '@oen.web.vue2/icons'
export default {
  components: {
    AppList,
    XeListItem,
    AppIcon,
    IconMyself,
    IconSomeoneElse,
    IconBusiness,
  },
  emits: ['input', 'addMyself', 'addSomeoneElse', 'addBusiness'],
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
    }
  },
}
</script>

<style scoped></style>
