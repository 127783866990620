<template>
  <div>
    <AppInputDropdown
      v-model="paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value"
      :placeholder="$t('SendMoneyReasonForTransfer.PleaseSpecifyText').value"
      :label="$t('SendMoneyReasonForTransfer.ReasonForTransferLabel').value"
      :options="paymentReferenceStore.reasonForTransferList"
      analytics-name="reason-for-transfer"
      selected-display-field="text"
      selected-value-field="value"
      :disabled="isReasonForTransferDropdownDisabled"
      class="reason-for-transfer-dropdown"
    />

    <AppInputText
      v-if="
        paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value.includes('_OTHER')
      "
      v-model="paymentReferenceStore.paymentReferenceForm.reasonForTransferText.$value"
      analytics-name="reason-for-transfer-other"
      :validation="paymentReferenceStore.paymentReferenceForm.reasonForTransferText"
    />

    <PaymentReferenceModal v-if="shouldShowPaymentReference" :recipient-name="recipientName" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  onBeforeMount,
  nextTick,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { required, helpers } from '@vuelidate/validators'
import { useRouter } from '@galileo/composables/useRouter'

import PaymentReferenceModal from '@galileo/components/Views/SendMoney/SendMoneyReasonForTransfer/PaymentReferenceModal'

import { useMediaQuery, AppInputDropdown, AppInputText } from '@oen.web.vue2/ui'

import { DeliveryMethod, PaymentMethod } from '@galileo/models/Transaction/app'

import {
  useI18nStore,
  useRecipientsStore,
  useSendMoneyStore,
  useResourcesStore,
  usePaymentReferenceStore,
} from '@galileo/stores'
import { storeToRefs } from 'pinia'
import { DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'SendMoneyReasonForTransfer',
  components: {
    AppInputDropdown,
    AppInputText,
    PaymentReferenceModal,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    recipientName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const shouldShowPaymentReference = computed(
        () => sendMoneyStore.form.deliveryMethod === DELIVERY_METHODS.BANK_ACCOUNT
      )

    sendMoneyStore.setActiveStep(router.currentRoute.path)

    const alphaNumValidator = helpers.regex(/^[a-zA-Z0-9 ()+-/?\\.\\,:]{2,}$/i)
    const maxLenghtValueValidator = helpers.regex(/^.{0,35}$/i)

    const isReferenceValidValue = ref(true)

    const reference = ref(null)

    const isReasonForTransferDropdownDisabled = computed(() => {
      if (paymentReferenceStore.reasonForTransferList.length === 1) {
        paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value =
          paymentReferenceStore.reasonForTransferList[0].value
        return true
      }
      return false
    })

    onMounted(() => {
      const {
        transferReason: initialTransferReason,
        transferReasonText: initialTransferReasonText,
        transferReferenceText: initialTransferReferenceText,
      } = sendMoneyStore.form
    })

    return {
      $t,
      reference,
      mq: useMediaQuery(),
      isReasonForTransferDropdownDisabled,
      shouldShowPaymentReference,
      paymentReferenceStore,
    }
  },
}
</script>

<style scoped>
.bordered-toggle {
  @apply border px-4 py-6 rounded-2xl;
  @apply border-b !important;
}
::v-deep .input-label {
  @apply text-base font-normal;
}

::v-deep .reason-for-transfer-dropdown.input.input--disabled .input-wrapper {
  color: black;
}
::v-deep .reason-for-transfer-dropdown.input.input--disabled .input-wrapper svg {
  display: none;
}
::v-deep .reason-for-transfer-dropdown.input.input--disabled .input-label {
  color: black;
}

::v-deep .p-dropdown-items-wrapper {
  width: 100%;
}
</style>
