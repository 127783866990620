import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { useRecipientsStore } from '@galileo/stores'

export default function useRecipientAddressFiller(setFormFieldValue, visibilityCallback = null) {
  const onSelectAddress = (address) => {
    const addressLinePropName =
      specialCountries[address.countryCode]?.addressLine1 || specialCountries.default.addressLine1
    const addressLine2PropName =
      specialCountries[address.countryCode]?.addressLine2 || specialCountries.default.addressLine2

    let addressLine = removeDiacritics(translit(address[addressLinePropName]))

    let addressLine2 = removeDiacritics(translit(address.addressLine2))

    if (address[addressLine2PropName]) {
      addressLine = `${addressLine}, ${removeDiacritics(translit(address[addressLine2PropName]))}`

      // check if not too long with line1 otherwise go back
      const addressLine1Regex = useRecipientsStore().getAddressLine1FieldRegex
      if (!addressLine1Regex.test(addressLine)) {
        addressLine = removeDiacritics(translit(address[addressLinePropName]))
      }
    }

    setFormFieldValue('ADDRESS_LINE1', addressLine)
    setFormFieldValue('ADDRESS_LINE2', addressLine2)

    const postCode = address.postCode.replace(' ', '')
    setFormFieldValue('RECIPIENT_POST_CODE', removeDiacritics(postCode))
    setFormFieldValue('RECIPIENT_CITY', removeDiacritics(address.city))
    setFormFieldValue('RECIPIENT_STATE', address.provinceCode)

    if (visibilityCallback) {
      visibilityCallback(addressLine)
    }
  }

  const onCountryChange = async (clearValue = null) => {
    if (typeof clearValue === 'function') {
      await clearValue()
    }
    setFormFieldValue('ADDRESS_LINE1', '')
    setFormFieldValue('ADDRESS_LINE2', '')
    setFormFieldValue('RECIPIENT_POST_CODE', '')
    setFormFieldValue('RECIPIENT_CITY', '')
    setFormFieldValue('RECIPIENT_STATE', '')
  }

  return { onSelectAddress, onCountryChange }
}

const specialCountries = {
  AF: { addressLine1: 'city', addressLine2: 'provinceName' },
  GH: {
    addressLine1: 'city',
    addressLine2: 'provinceName',
  },
  CD: {
    addressLine1: 'addressLine2',
    addressLine2: 'city',
  },
  CG: { addressLine1: 'addressLine2', addressLine2: 'city' },
  default: {
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
  },
}
