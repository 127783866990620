<template>
  <AppModal v-model="model" :title="$t('ComponentAddRecipientTypeModal.Header').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #right>
          <XeBackButton analytics-name="add-recipient-type-close" @click="close" />
        </template>
        <h1>{{ $t('ComponentAddRecipientTypeModal.Header').value }}</h1>
      </AppModalHeader>
    </template>

    <p
      v-if="isSendMoney"
      class="card-warning"
      v-html="$t('ComponentAddRecipientType.WarningMessage', { currency }).value"
    ></p>

    <RecipientTypeListCorporate
      v-if="isCorporateAccount"
      @addMyself="() => $emit('addMyself')"
      @addSomeoneElse="() => $emit('addSomeoneElse')"
      @addBusiness="() => $emit('addBusiness')"
    />
    <RecipientTypeList
      v-else
      @addMyself="() => $emit('addMyself')"
      @addSomeoneElse="() => $emit('addSomeoneElse')"
      @addBusiness="() => $emit('addBusiness')"
    />
  </AppModal>
</template>

<script>
import { ref } from '@vue/composition-api'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { storeToRefs } from 'pinia'

import RecipientTypeList from '@galileo/components/Views/Recipient/RecipientTypeList/RecipientTypeList'
import RecipientTypeListCorporate from '@galileo/components/Views/Recipient/RecipientTypeList/RecipientTypeListCorporate'

import { useVModel, AppModal, AppModalHeader } from '@oen.web.vue2/ui'

import { useI18nStore, useAuthStore, useSendMoneyStore } from '@galileo/stores'


export default {
  name: 'AddRecipientTypeModal',
  emits: ['input', 'addMyself', 'addSomeoneElse', 'addBusiness'],
  components: {
    AppModal,
    AppModalHeader,
    XeBackButton,
    RecipientTypeList,
    RecipientTypeListCorporate,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    const currency = sendMoneyStore.form.currencyTo

    const user = authStore.user
    const initials =
      user.customer.firstName.charAt(0).toUpperCase() +
      user.customer.lastName.charAt(0).toUpperCase()

    const { isCorporateAccount } = storeToRefs(authStore)

    const close = () => { // TEMPORARY SOLUTION
      emit('close')
      model.value = false
    }

    return {
      initials,
      model,
      currency,
      $t,
      isCorporateAccount,
      close,
    }
  },
}
</script>

<style scoped>
.card-header {
  ::v-deep .card-header-title {
    @apply text-lg leading-6 h-auto;
  }
  @screen sm {
    @apply text-lg leading-6 h-auto;
    @apply px-12 py-12;
  }
}

.card-header-right {
  @apply flex-1;

  @screen sm {
    @apply flex-1;
  }
}

::v-deep .card-content {
  @apply px-4;

  @screen sm {
    @apply px-12;
  }
}

.card-warning {
  background-color: #fff5e3;
  color: #ca542d;
  font-size: 14px;
  border-radius: 4px;
  @apply p-2;
  @apply pl-3;
  @apply pr-3;
  @apply mb-4;
}
</style>
