<template>
  <AppList>
    <XeListItem
      v-if="!showOtherBusinessOnly"
      analytics-name="add-recipient-type-myself"
      :text="$t('ComponentAddRecipientTypeModalCorporate.ButtonMyBusiness').value"
      :select="() => $emit('addMyself')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconMyself />
        </AppIcon>
      </template>
    </XeListItem>
    <XeListItem
      v-if="!showOtherBusinessOnly"
      analytics-name="add-recipient-type-someone-else"
      :text="$t('ComponentAddRecipientTypeModalCorporate.ButtonSomeoneElse').value"
      :subTitle="$t('ComponentAddRecipientTypeModalCorporate.ButtonSomeoneElseDescription').value"
      :select="() => $emit('addSomeoneElse')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconSomeoneElse />
        </AppIcon>
      </template>
    </XeListItem>

    <XeListItem
      analytics-name="add-recipient-type-business"
      :text="$t('ComponentAddRecipientTypeModalCorporate.ButtonAnotherBusiness').value"
      :subTitle="
        $t('ComponentAddRecipientTypeModalCorporate.ButtonAnotherBusinessDescription').value
      "
      :select="() => $emit('addBusiness')"
    >
      <template #figure-icon>
        <AppIcon name=" ">
          <IconBusiness />
        </AppIcon>
      </template>
    </XeListItem>
  </AppList>
</template>

<script>
import { useI18nStore, useAuthStore } from '@galileo/stores'
import XeListItem from '@galileo/components/XeListItem/XeListItem'

import { AppList, AppIcon } from '@oen.web.vue2/ui'

import { IconMyself, IconSomeoneElse, IconBusiness } from '@oen.web.vue2/icons'
import { computed, ref } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
export default {
  components: {
    AppList,
    XeListItem,
    AppIcon,
    IconMyself,
    IconSomeoneElse,
    IconBusiness,
  },
  emits: ['input', 'addMyself', 'addSomeoneElse', 'addBusiness'],
  setup() {
    const { $t } = useI18nStore()
    const { user, isCorporateAccount } = storeToRefs(useAuthStore())
    const restrictedCountries = ['MY']

    const showOtherBusinessOnly = ref(
      restrictedCountries.includes(user.value.country) && isCorporateAccount.value
    )

    return {
      $t,
      showOtherBusinessOnly,
    }
  },
}
</script>

<style scoped></style>
